import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import { fg } from '@confluence/feature-gating';

export const getCreateButtonDisabledMessage = (
	isContentArchived?: boolean,
	userCanAddComments?: boolean,
	contentType?: 'page' | 'blogpost' | 'whiteboard' | 'database' | 'embed',
) => {
	if (isContentArchived) {
		return (
			<FormattedMessage
				description="Tooltip for the unsupported inline comment for the archive page"
				id="highlight-actions.inline-comments.comment.unsupported.archive"
				defaultMessage="Comments can’t be added to archived items"
			/>
		);
	} else if (!userCanAddComments) {
		return (
			<FormattedMessage
				id="highlight-actions.inline-comments.comment.no.permission"
				description="Tooltip for when the user does not have permission to add inline comments"
				defaultMessage="{contentType, select, blogpost {You do not have permission to add comments to this blogpost} page {You do not have permission to add comments to this page} other {You do not have permission to add comments to this page}}"
				values={{ contentType }}
			/>
		);
	} else {
		return fg('editor_inline_comments_on_inline_nodes') ? (
			<FormattedMessage
				id="highlight-actions.inline-comments.comment.create.disabled"
				description="Error message to communicate to the user they can only do the current action in certain contexts"
				defaultMessage="You can only comment on text, headings, emojis, dates, mentions, links, and statuses."
			/>
		) : (
			<FormattedMessage
				id="highlight-actions.inline-comments.comment.unsupported"
				description="Tooltip for the unsupported inline comment on the content that contains an emoji, a macro, or an @mention"
				defaultMessage="Comments can only be added to text that doesn’t contain an emoji, a macro, or an @mention"
			/>
		);
	}
};
